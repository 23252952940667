import React from 'react';
import '../graphql-fragments/localeFragments.ts';

import IndexPage from '../components/IndexPage';

const CitizenshipInIndexPage = (): React.ReactElement => {
  return (
    <IndexPage
      country="in"
      pageTheme="citizenship"
      googleTagManagerTrackingId="GTM-P23PHHS"
    ></IndexPage>
  );
};

export default CitizenshipInIndexPage;
